import "./spinningButton.scss"
import { motion } from "framer-motion";
import { Link } from "react-scroll";

const button = {
    rest: { scale: 1 },
    hover: { scale: 1.1 },
    pressed: { scale: 0.90 }
};

function SpinningButton() {
    const text = "Tap · Contacto · Tap · Contacto · "
    const lenght = text.length;
    const deg = 360 / lenght;

    return (
        <div className="spinning-button-wrapper">
            <div className="spinning-button">
                <p>
                    {text.split("").map((letra, i) => (
                        <span
                            key={i}
                            style={{
                                transform: `rotate(${deg * i}deg)`,
                            }}
                        >
                            {letra}
                        </span>
                    ))}
                </p>
            </div>
            <motion.div className="imgContainer"
                variants={button}
                initial="rest"
                whileHover="hover"
                whileTap="pressed"
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
                <Link to="Contacto" smooth={true}>
                    <img src="img/bio.jpg" alt="Cristian Ferreira"/>
                </Link>
            </motion.div>
        </div>
    );
}

export default SpinningButton;