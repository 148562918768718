import "./player.scss"
import YoutubePlayer from "react-player/youtube";
import { motion } from "framer-motion";

const Player = ({ url }) => {
    return (
        <motion.div className="player"
            whileTap={{ scale: 0.90 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
            <YoutubePlayer
                url={url}
                light
                width={280}
                height={156.50}
                style={{ filter: "grayscale() brightness(130%)" }}
            />
        </motion.div>
    );
}

export default Player;