import "./contacto.scss"
import SpringText from "../springText/SpringText";
import Mediaicons from "./../social/Mediaicons";
import emailjs from '@emailjs/browser';
import { Link } from "react-scroll";
import { useEffect, useState, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";


const right = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
}

const left = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
}


const Contacto = () => {

    const ref = useRef(null);
    const formRef = useRef();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const isInView = useInView(ref, { amount: 0.3, once: true });

    const mainControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
        }
    }, [isInView, mainControls]);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm("service_zu3vukn", "template_daas72i", formRef.current, {
                publicKey: "hCFAdG98TnaWuTj9B",
            })
            .then(
                (result) => {
                    setSuccess(true)
                },
                (error) => {
                    setError(true);
                },
            );
        e.target.reset();
    };
    setTimeout(() => {
        setSuccess(false)
        setError(false)
    }, 5000);

    return (
        <div className="contacto">
            <div className="wrapper" ref={ref}>
                <div className="textContainer">
                    <SpringText text="CONTACTO" />

                    <motion.div className="data"
                        variants={left}
                        initial="hidden"
                        animate={mainControls}
                        transition={{ duration: 0.5, delay: 1 }}
                    >
                        <i className="fa fa-phone icon" />
                        <h2>+ 54 9 11 6519 4819</h2>
                        <i className="fa fa-envelope icon" />
                        <a className="mail"
                            href="mailto:cristian._.ferreira@hotmail.com"
                            target="_blank"
                            rel="noreferrer"
                        >cristian._.ferreira@hotmail.com</a>
                        <Mediaicons />
                    </motion.div>
                </div>
                <div className="formContainer">
                    <motion.form
                        ref={formRef}
                        onSubmit={sendEmail}
                        variants={right}
                        initial="hidden"
                        animate={mainControls}
                        transition={{ duration: 0.5, delay: 1 }}
                    >
                        <input type="text" placeholder="Nombre" name="name" autoComplete="off" required />
                        <input type="email" placeholder="Email" name="email" autoComplete="off" required />
                        <textarea rows={9} placeholder="Mensage" name="message" autoComplete="off" required />
                        <motion.button type="submit"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.90 }}
                            transition={{ type: "spring", stiffness: 400, damping: 10 }}
                        >Enviar</motion.button>
                        <div className="response">
                            {success ? <p>Mensaje Enviado</p> : null}
                            {error ? <p>Algo Salió Mal</p> : null}
                        </div>
                    </motion.form>
                </div>
            </div>

            <div className="footer">
                <p>Copyright &copy; 2024 Cristian Ferreira | All Rights Reserved.</p>
                <Link to="Home" smooth={true}><i className="fa fa-arrow-up"></i></Link>
            </div>

        </div>
    );
}

export default Contacto;