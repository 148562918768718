import "./hero.scss"
import { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

const textVariants = {
    initial: {
        x: -150,
        opacity: 0,
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            staggerChildren: 0.1,
        },
    },
    scrollButton: {
        opacity: 0,
        y: 10,
        transition: {
            duration: 2,
            repeat: Infinity,
        },
    },
};


const Hero = () => {
    const ref = useRef();

    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start start", "end start"],
    });

    const yText = useTransform(scrollYProgress, [0, 1], ["0%", "400%"]);
    const yBg = useTransform(scrollYProgress, [0, 1], ["0%", "25%"]);

    return (
        <div className="hero" ref={ref}>
            <motion.div className="banner" style={{ y: yBg }}
                initial={{ opacity: 0, scale: 1.2 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
            ></motion.div>
            <motion.div className="textContainer"
                style={{ y: yText }}
                variants={textVariants}
                initial="initial"
                animate="animate"
            >
                <motion.h1 className="principal" variants={textVariants}>Cristian Ferreira</motion.h1>
                <motion.h3 className="secondary" variants={textVariants}>Bajista • Contrabajista</motion.h3>
                <motion.img
                    variants={textVariants}
                    animate="scrollButton"
                    src="img/scroll.png"
                    alt="scrollButton"
                />
            </motion.div>
        </div>
    );
}

export default Hero;
