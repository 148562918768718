import "./videos.scss";
import SpringText from "../springText/SpringText";
import Player from "./player/Player";
import { Reveal } from "../reveal/Reveal";
import { useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";

const Videos = () => {

    const isDesktop = useMediaQuery('(min-width:960px)');

    return (
        <div className="videos">
            <SpringText text="VIDEOS" />

            <motion.div className="fuzzy"
                initial={{ transform: "translateX(-10%) translateY(-10%)" }}
                animate={{ transform: "translateX(10%) translateY(10%)"  }}
                transition={{
                    repeat: Infinity,
                    duration: 0.2,
                    ease: "linear",
                    repeatType: "mirror",
                }}
            />

            <div className="wrapper">
                <Reveal animate={isDesktop ? "right" : "bottom"}>
                    <Player url="https://www.youtube.com/embed/j7ly6ysFaqo" />
                </Reveal>
                <Reveal animate="bottom">
                    <Player url="https://www.youtube.com/embed/hgFwSjy9g5U" />
                </Reveal>
                <Reveal animate={isDesktop ? "left" : "bottom"}>
                    <Player url="https://www.youtube.com/embed/PsygWNE5zXs" />
                </Reveal>
                <Reveal animate={isDesktop ? "right" : "bottom"}>
                    <Player url="https://www.youtube.com/embed/1w4iiPb-iYs" />
                </Reveal>
                <Reveal animate="bottom">
                    <Player url="https://youtu.be/0yG6B6YQa94" />
                </Reveal>
                <Reveal animate={isDesktop ? "left" : "bottom"}>
                    <Player url="https://www.youtube.com/embed/L3oEQPJMyzg" />
                </Reveal>
                <Reveal animate={isDesktop ? "right" : "bottom"}>
                    <Player url="https://www.youtube.com/embed/vGyic01IVuI" />
                </Reveal>
                <Reveal animate="bottom">
                    <Player url="https://www.youtube.com/embed/yNsjLiae5Xc" />
                </Reveal>
                <Reveal animate={isDesktop ? "left" : "bottom"}>
                    <Player url="https://www.youtube.com/embed/uWoxn7hhXyw" />
                </Reveal>
            </div>
        </div>
    );
}

export default Videos;