import "./sidebar.scss";
import Links from "./links/Links";
import Humburger from "./hamburger/Hamburger";
import { useState } from "react";
import { motion } from "framer-motion";

const variants = {
    open: {
        clipPath: "circle(1200px at 50px 50px)",
        transition: {
            type: "spring",
            stiffness: 20,
        }
    },
    closed: {
        clipPath: "circle(25px at 50px 50px)",
        transition: {
            delay: 0.5,
            type: "spring",
            stiffness: 400,
            damping: 40,
        }
    }
}

const Sidebar = () => {
    const [open, setOpen] = useState(false);

    return (
        <motion.div className="sidebar" animate={open ? "open" : "closed"}>
            <motion.div className="bg" initial={{opacity:0}} animate={{opacity:1}} variants={variants}>
                <Links setOpen={setOpen} />
            </motion.div>
            <Humburger setOpen={setOpen} />
        </motion.div>
    );
}

export default Sidebar;