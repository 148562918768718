import "./bio.scss"
import SpringText from "../springText/SpringText";
import SpinningButton from "../spinningButton/SpinningButton";
import { Reveal } from "../reveal/Reveal";

const Bio = () => {
    return (
        <div className="bio">
            <SpringText text="BIO" />

            <div className="resume">
                <Reveal animate="right">
                <p>Bajista y Contrabajista Argentino, egresado de la carrera de Músico interprete en Música Popular de la EPM (Escuela Popular de Música), dónde estudió bajo eléctrico con <span>Bucky Arcella</span> y <span>Carlos Madariaga</span>. Estudió contrabajo con <span>Jerónimo Carmona</span> y <span>Ezequiel Dutil</span>. Formó parte de los ensambles de jazz de <span>Pablo Bobrowicky</span>. Realizó ensambles de improvisación con <span>Rodrigo Domínguez</span> y con <span>Tony Malaby</span>. Ha participado de clínicas y masterclass dictadas por <span>William Parker</span>, <span>Drew Gress</span>, <span>Michael Formanek</span>, <span>Ari Hoenig</span>, <span>Johannes Weidenmuller</span> y <span>Kenny Werner</span> entre otros.
                </p>
                </Reveal>

                <Reveal animate="left">
                <p>Ha formado parte de una amplia variedad de grupos y compartido escenario con varios de los músicos de jazz
                    de la escena local, así como también músicos de otros estilos y corrientes estéticas, manteniendo el foco en la improvisación como medio de expresión e investigación.
                </p>
                </Reveal>

                <Reveal animate="right">
                <p>Se ha presentado en el <span>Festival Internacional Muchas Músicas (FIMM)</span>; <span>Festival Nuevas Músicas por la Memoria</span> y <span>XIII Festival Internacional de Jazz de El Bolsón</span> entre otros.
                </p>
                </Reveal>

                <Reveal animate="left">
                <p>En el ámbito docente, ha estado enseñando por casi 20 años, impartiendo clases privadas de bajo eléctrico, contrabajo, improvisación, lenguaje musical y armonía. Ha dirigido ensambles de diferentes estilos dentro de la música popular, así como ensambles dedicados exclusivamente a explorar el lenguaje jazzístico.
                </p>
                </Reveal>
            </div>

            <Reveal animate="bottom">
                <SpinningButton />
            </Reveal>
        </div>
    );
}

export default Bio;