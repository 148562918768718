import { motion } from "framer-motion";

const Hamburger = ({ setOpen }) => {
    return (
        <button onClick={() => setOpen(prev => !prev)}>
            <motion.span className="burgerline"
                variants={{
                    open: {
                        y: 8,
                        rotate: -45,
                    },
                    closed: {
                        y: 0,
                        rotate: 0,
                    },
                }} />
            <motion.span className="burgerline"
                variants={{
                    open: {
                        opacity: 0,
                    },
                    closed: {
                        opacity: 1,
                    },
                }} />
            <motion.span className="burgerline"
                variants={{
                    open: {
                        y: -8,
                        rotate: 45,
                    },
                    closed: {
                        y: 0,
                        rotate: 0,
                    },
                }} />
        </button>
    );
}

export default Hamburger;