import "./springText.scss"
import { motion, useInView } from "framer-motion";
import { useRef } from "react";

const SpringText = ({ text }) => {
    const letters = Array.from(text);

    const ref = useRef()
    const IsInView = useInView(ref, { amount: 0.5, once: true })

    const container = {
        hidden: { opacity: 0 },
        visible: (i = 1) => ({
            opacity: 1,
            transition: { staggerChildren: 0.1, delayChildren: 0.02 * i },
        }),
    };

    const child = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 100,
            },
        },
        hidden: {
            opacity: 0,
            y: 100,
        },
    };

    return (
        <motion.div className="containerText"
            ref={ref}
            variants={container}
            initial="hidden"
            animate={IsInView && "visible"}
        >
            {letters.map((letter, index) => (
                <motion.span className="letter" 
                variants={child} 
                key={index}
                >
                    {letter}
                </motion.span>
            ))}
        </motion.div>
    );
};

export default SpringText;