import "./mediaicons.scss"

const Mediaicons = () => {
    return (
        <div className="mediaicons">
            <a href="https://www.instagram.com/cristian._.ferreira/" target="_blank" rel="noreferrer">
                <i className="fa fa-instagram"></i></a>
            <a href="https://www.youtube.com/@cristian._.ferreira" target="_blank" rel="noreferrer">
                <i className="fa fa-youtube-play"></i></a>
            <a href="https://wa.me/+5491165194819" target="_blank" rel="noreferrer">
                <i className="fa fa-whatsapp"></i></a>
        </div>
    );
}

export default Mediaicons;