import "./disco.scss"

const Disco = (props) => {

    const { art, year, title, artist, detail, spotifyLink, bandcampLink, youtubeLink } = props

    return (
        <div className="disco">
            <div className="textContainer">
                <h1>{title}</h1>
                <h2>{artist}</h2>
                { detail !== 0 && <h3>{detail}</h3> }
                <h4>{year}</h4>
            </div>

            <div className="art" style={{backgroundImage:`url(${art})`}}></div>

            <div className="listenContainer">
                {spotifyLink !== 0 &&
                    <a href={spotifyLink} target="_blank" rel="noreferrer"><i
                        className="fa fa-spotify"></i></a>
                }
                {bandcampLink !== 0 &&
                    <a href={bandcampLink} target="_blank" rel="noreferrer"><i
                        className="fa fa-bandcamp"></i></a>
                }
                {youtubeLink !== 0 &&
                    <a href={youtubeLink} target="_blank" rel="noreferrer"><i
                        className="fa fa-youtube-play"></i></a>
                }
            </div>
        </div>
    );
}

export default Disco;