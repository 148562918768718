import "./navbar.scss";
import Mediaicons from "./../social/Mediaicons";
import Sidebar from "../sidebar/Sidebar";
import { motion } from "framer-motion";

const Navbar = () => {
    return (
        <nav className="navbar">
            <Sidebar />
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8 }}
            >
                <Mediaicons />
            </motion.div>
        </nav >
    );
}

export default Navbar;