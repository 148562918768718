import "./galeria.scss"
import SpringText from "../springText/SpringText";
import { Reveal } from "../reveal/Reveal";

const Galeria = () => {
    return (
        <div className="galeria">
            <SpringText text="GALERIA" />
            <div className="wrapper">
                {images.map((image, id) => (
                    <Reveal animate="bottom" key={image.id}>
                        <img src={image.img} alt="Cristian Ferreira"/>
                    </Reveal>
                ))}
            </div>

        </div>
    );
}

export default Galeria;

const images = [
    {
        id: 1,
        img: "img/galeria/01.jpg",

    },
    {
        id: 2,
        img: "img/galeria/02.jpg",

    },
    {
        id: 3,
        img: "img/galeria/03.jpg",

    },
    {
        id: 4,
        img: "img/galeria/04.jpg",

    },
    {
        id: 5,
        img: "img/galeria/05.jpg",

    },
    {
        id: 6,
        img: "img/galeria/06.jpg",

    },
    {
        id: 7,
        img: "img/galeria/07.jpg",

    },
    {
        id: 8,
        img: "img/galeria/08.jpg",

    },
    {
        id: 9,
        img: "img/galeria/09.jpg",

    },
    {
        id: 10,
        img: "img/galeria/10.jpg",

    },
    // ------------------------------------10
    {
        id: 11,
        img: "img/galeria/11.jpg",

    },
    {
        id: 12,
        img: "img/galeria/12.jpg",

    },
    {
        id: 13,
        img: "img/galeria/13.jpg",

    },
    {
        id: 14,
        img: "img/galeria/14.jpg",

    },
    {
        id: 15,
        img: "img/galeria/15.jpg",

    },
    {
        id: 16,
        img: "img/galeria/16.jpg",

    },
    {
        id: 17,
        img: "img/galeria/17.jpg",

    },
    {
        id: 18,
        img: "img/galeria/18.jpg",

    },
    {
        id: 19,
        img: "img/galeria/19.jpg",

    },
    {
        id: 20,
        img: "img/galeria/20.jpg",

    },
    // ------------------------------------20
    {
        id: 21,
        img: "img/galeria/21.jpg",

    },
    {
        id: 22,
        img: "img/galeria/22.jpg",

    },
    {
        id: 23,
        img: "img/galeria/23.jpg",

    },
    {
        id: 24,
        img: "img/galeria/24.jpg",

    },
    {
        id: 25,
        img: "img/galeria/25.jpg",

    },
    {
        id: 26,
        img: "img/galeria/26.jpg",

    },
    {
        id: 27,
        img: "img/galeria/27.jpg",

    },
    {
        id: 28,
        img: "img/galeria/28.jpg",

    },
    {
        id: 29,
        img: "img/galeria/29.jpg",

    },
    {
        id: 30,
        img: "img/galeria/30.jpg",

    },
    // ------------------------------------30
    {
        id: 31,
        img: "img/galeria/31.jpg",

    },
    {
        id: 32,
        img: "img/galeria/32.jpg",

    },
    {
        id: 33,
        img: "img/galeria/33.jpg",

    },
    {
        id: 34,
        img: "img/galeria/34.jpg",

    },
    {
        id: 35,
        img: "img/galeria/35.jpg",

    },
    {
        id: 36,
        img: "img/galeria/36.jpg",

    },
    {
        id: 37,
        img: "img/galeria/37.jpg",

    },
    
    // ------------------------------------37
];