import { Link } from "react-scroll";
import { motion } from "framer-motion";

const variants = {
    open: {
        transition: {
            staggerChildren: 0.1,
            delayChildren: 0.2,
        }
    },
    closed: {
        transition: {
            staggerChildren: 0.05,
            staggerDirection: -1,
        }
    }
}

const itemVariants = {
    open: {
        y: 0,
        opacity: 1,

    },
    closed: {
        y: 50,
        opacity: 0,
    }
}

const Links = ({ setOpen }) => {
    const items = ["Home", "Bio", "Discos", "Videos", "Galeria", "Contacto"];

    return (
        <motion.div className="links" variants={variants}>
            {items.map((item) => (
                <motion.div key={item}
                    variants={itemVariants}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                >
                    <Link 
                    activeClass="active"
                    onClick={() => setOpen(prev => !prev)} 
                    to={item} 
                    smooth={true} 
                    spy={true}
                    >{item}</Link>
                </motion.div>
            ))}
        </motion.div>
    );
};

export default Links;
