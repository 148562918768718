import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

export const Reveal = ({ children, animate }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { amount: 0.3, once: true });

    const mainControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
        }
    }, [isInView, mainControls]);

    const variants = {
        bottom: {
            hidden: { opacity: 0, y: 100 },
            visible: { opacity: 1, y: 0 },
        },

        left: {
            hidden: { opacity: 0, x: 100 },
            visible: { opacity: 1, x: 0 },
        },

        right: {
            hidden: { opacity: 0, x: -100 },
            visible: { opacity: 1, x: 0 },
        }
    }

    return (
        <div ref={ref} style={{ position: "relative", width: "fit-content", overflow: "hidden" }}>
            <motion.div
                variants={variants[animate]}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: 0.5, delay: 1 }}
            >
                {children}</motion.div>
        </div>
    );
};