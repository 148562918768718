import "./discos.scss"
import SpringText from "../springText/SpringText";
import Disco from "./disco/Disco";
import { Reveal } from "../reveal/Reveal";
import { useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";

const Discos = () => {

    const isDesktop = useMediaQuery('(min-width:960px)');

    return (
        <div className="discos">
            <SpringText text="DISCOS" />
            <motion.i className="fa fa-hand-pointer-o icon"
                whileInView={{ opacity: 0 }}
                transition={{ duration: 2, repeat: 3 }}
            />
            <div className="wrapper">
                <Reveal animate={isDesktop ? "right" : "bottom"}>
                    <Disco
                        art="img/discos/diarios.jpg"
                        year={2022}
                        title="Diarios"
                        artist="Soko Rodrigo / Virginia Innocenti"
                        detail="Independiente"
                        spotifyLink="https://open.spotify.com/album/3QcqA0KY8yyjp6lO9PQVxT"
                        bandcampLink={0}
                        youtubeLink={0}
                    />
                </Reveal>
                <Reveal animate="bottom">
                    <Disco
                        art="img/discos/cometogether.jpg"
                        year={2020}
                        title="Canciones Importadas"
                        artist="Reincidente"
                        detail="Independiente / Track 5"
                        spotifyLink="https://open.spotify.com/album/62lVfvl2oahbhMSNHltzAo"
                        bandcampLink="https://reincidente.bandcamp.com/album/canciones-importadas"
                        youtubeLink="https://youtu.be/lxdaSNOChoY?si=LhhCP0UK8I0tBLVv"
                    />
                </Reveal>
                <Reveal animate={isDesktop ? "left" : "bottom"}>
                    <Disco
                        art="img/discos/npd.jpg"
                        year={2019}
                        title="No Puedo Dejarlo"
                        artist="Nacho Ladisa Blues Club"
                        detail="Independiente / Single"
                        spotifyLink="https://open.spotify.com/album/4zdGgSwn6rKT5acYNEP7sX"
                        bandcampLink={0}
                        youtubeLink={0}
                    />
                </Reveal>

                <Reveal animate={isDesktop ? "right" : "bottom"}>
                    <Disco
                        art="img/discos/camalote.jpg"
                        year={2019}
                        title="Camalote"
                        artist="Camalote Trío"
                        detail="ZZZ Musique Label"
                        spotifyLink="https://open.spotify.com/album/3Od7iF6mK7CjWZPRdZaEHT"
                        bandcampLink={0}
                        youtubeLink={0}
                    />
                </Reveal>
                <Reveal animate="bottom">
                    <Disco
                        art="img/discos/equilibrios.jpg"
                        year={2018}
                        title="Equilibrios Inestables"
                        artist="Mauro Vicino"
                        detail="Independiente"
                        spotifyLink="https://open.spotify.com/album/0JlTbD6m6IGTvPtDJq2OxR"
                        bandcampLink={0}
                        youtubeLink={0}
                    />
                </Reveal>
                <Reveal animate={isDesktop ? "left" : "bottom"}>
                    <Disco
                        art="img/discos/otraforma.jpg"
                        year={2016}
                        title="Otra Forma del Tiempo"
                        artist="Alunco Quinteto"
                        detail="Independiente / Film + Música"
                        spotifyLink={0}
                        bandcampLink={0}
                        youtubeLink="https://youtu.be/dPQrtm5RGGk"
                    />
                </Reveal>

                <Reveal animate={isDesktop ? "right" : "bottom"}>
                    <Disco
                        art="img/discos/alunco.jpg"
                        year={2014}
                        title="Alunco"
                        artist="Alunco Quinteto"
                        detail="Independiente"
                        spotifyLink="https://open.spotify.com/album/3B50rWyESffaZqoVEYF0VP"
                        bandcampLink="https://aluncoquinteto.bandcamp.com/album/alunco"
                        youtubeLink="https://youtu.be/dg6qqv6JClQ"
                    />
                </Reveal>
                <Reveal animate="bottom">
                    <Disco
                        art="img/discos/5guitarras.jpg"
                        year={2013}
                        title="5 Guitarras de Blues"
                        artist="Julio Fabiani"
                        detail="Blues en Movimiento / Tracks 5 & 8"
                        spotifyLink="https://open.spotify.com/album/4LzIz82EaSCAxyFa5qLDTk"
                        bandcampLink={0}
                        youtubeLink={0}
                    />
                </Reveal>
                <Reveal animate={isDesktop ? "left" : "bottom"}>
                    <Disco
                        art="img/discos/bluesclub.jpg"
                        year={2012}
                        title="Blues Club"
                        artist="Nacho Ladisa Blues Club"
                        detail="Blues en Movimiento"
                        spotifyLink="https://open.spotify.com/album/56J3Z1ZxeMlGy8wYGGgWMW"
                        bandcampLink="https://nacholadisabluesclub.bandcamp.com/album/nacho-ladisa-blues-club"
                        youtubeLink={0}
                    />
                </Reveal>
            </div>
        </div>
    );
}

export default Discos;