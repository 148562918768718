import "./App.scss"
import Hero from "./components/hero/Hero";
import Navbar from "./components/navbar/Navbar";
import Bio from "./components/bio/Bio";
import Discos from "./components/discos/Discos";
import Videos from "./components/videos/Videos";
import Galeria from "./components/galeria/Galeria";
import Contacto from "./components/contacto/Contacto";

import { ReactLenis } from 'lenis/react';
import { useRef } from "react";
// import { useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";



function App() {

  // useEffect(() => {
  //   window.history.scrollRestoration = 'manual'
  // }, []);

  const home = useRef();

  const { scrollYProgress } = useScroll({
    target: home,
    offset: ["start start", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 1], ["100%", "-150%"]);

  return (
    <div>
      <ReactLenis root>
        <Navbar />
        <motion.section id="Home" ref={home} style={{ opacity }}>
          <Hero />
        </motion.section>
        <section id="Bio">
          <Bio />
        </section>
        <section id="Discos">
          <Discos />
        </section>
        <section id="Videos">
          <Videos />
        </section>
        <section id="Galeria">
          <Galeria />
        </section>
        <section id="Contacto">
          <Contacto />
        </section>
      </ReactLenis>
    </div>
  );
}

export default App;
